import React, { useMemo, useCallback } from 'react'
import { Grid, Box, Card, CardContent, IconButton, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PersonIcon from '@material-ui/icons/Person'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Menu from '../../../../../../Components/Menu/Menu'
import RoomDialog from '../../../../../../Components/RoomDialog/RoomDialog'
import { useRoomNavigation } from './hooks/useRoomNavigation'
import { useDialog } from '../../../../../../Components/RoomDialog/hooks/useDialog'
import { useDeleteLiveroom, DeleteLiveroomState } from './hooks/useDeleteLiveroom'
import RoomAccessDialog from '../../../../../../Components/RoomAccess/RoomAccessDialog/RoomAccessDialog'
import AppleTVLoginDialog from '../../../../../../Components/AppleTVLogin/AppleTVLoginDialog/AppleTVLoginDialog'
import { UserProfile, RoomRest } from '../../../../../../Models/apiEntities'
import { usePermissions } from '../../../../../../Common/hooks/usePermissions'
import usePermissionsRest, {
  PermissionType,
} from '../../../../../../Common/hooks/usePermissionsRest'
import { getCardBackgroundByRoom } from './background/cardBackground'
import DeleteOwnRoomDialog from '../../../../../../Components/DeleteRoomDialog/DeleteOwnRoomDialog/DeleteOwnRoomDialog'
import './RoomCard.scss'
import { useEnvironment } from '../../../../../../Providers/EnvironmentProvider'
import { EnvName } from '../../../../../../Common/environments/environments'
import { ReactComponent as SuiteIcon } from './suite.svg'

interface RoomCardProps {
  room: RoomRest
  currentProfile: UserProfile
  selectRoomToRemoveFromDashboard: (room: RoomRest) => void
  updateAvailableRoomsAfterDelete: () => void
  refreshRooms: () => void
}

/**
 * Room Card information
 * @param room room information
 * @param currentProfile current user profile
 * @constructor
 */
export const RoomCard: React.FC<RoomCardProps> = ({
  room,
  currentProfile,
  selectRoomToRemoveFromDashboard,
  updateAvailableRoomsAfterDelete,
  refreshRooms,
}) => {
  const { goToRoom, goToRecordings } = useRoomNavigation()
  const {
    deleteLiveroomState,
    deleteLiveroom,
    showDeleteConfirmation,
    closeDeleteConfirmation,
    isDeleteConfirmationOpen,
  } = useDeleteLiveroom(room, updateAvailableRoomsAfterDelete)

  const [isRoomDialogOpened, openRoomDialog, closeRoomDialog] = useDialog()
  const [isRoomAccessOpened, openRoomAccess, closeRoomAccess] = useDialog()
  const [isAppleTVDialogOpened, openAppleTVDialog, closeAppleTVDialog] = useDialog()

  const { checkPermission } = usePermissions({ defaultProfile: currentProfile })
  const { authorize } = usePermissionsRest()
  const isLite = useEnvironment().envName === EnvName.lite

  const menuOptions = useMemo(
    () => [
      {
        text: 'Room access',
        onClick: openRoomAccess,
        isAvailable: () => authorize(room, PermissionType.ROOM_INVITE_CREATE),
      },
      {
        text: 'Room settings',
        onClick: openRoomDialog,
        isAvailable: () => authorize(room, PermissionType.ROOM_UPDATE),
      },
      {
        text: 'Recordings',
        onClick: () => goToRecordings(room),
        isAvailable: () => authorize(room, PermissionType.SESSION_LIST),
      },
      { text: 'Apple TV sign-in', onClick: openAppleTVDialog, isAvailable: () => !isLite },
      {
        text: 'Delete room',
        onClick: showDeleteConfirmation,
        isAvailable: () =>
          authorize(room, PermissionType.ROOM_DELETE) && room.creator.id === currentProfile.id,
      },
      {
        text: 'Remove room from dashboard',
        onClick: () => selectRoomToRemoveFromDashboard(room),
        isAvailable: () =>
          !checkPermission({ run: 'delete', on: room }) &&
          !authorize(room, PermissionType.ROOM_DELETE),
      },
    ],
    [
      openRoomDialog,
      showDeleteConfirmation,
      openRoomAccess,
      goToRecordings,
      room,
      checkPermission,
      openAppleTVDialog,
      selectRoomToRemoveFromDashboard,
      isLite,
      authorize,
    ]
  )

  const onRoomDialogSuccess = () => {
    refreshRooms()
    closeRoomDialog()
  }

  const actionButton = useMemo(() => {
    return (
      <Menu
        button={(openMenu) => (
          <IconButton aria-label="settings" onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        menuOptions={menuOptions}
      />
    )
  }, [menuOptions])

  const backgroundImage = useMemo(() => {
    return {
      backgroundImage: getCardBackgroundByRoom(room.hash),
    }
  }, [room])

  const participantIcon = useMemo(
    () =>
      room.participantCount > 0 ? (
        <>
          <IconButton>
            <PersonIcon />
          </IconButton>
          <Box ml={-1.3} mt={-10} display="inline">
            <Typography
              align="right"
              display="inline"
              variant="caption"
              data-testid="participant-count"
            >
              {room.participantCount}
            </Typography>
          </Box>
        </>
      ) : null,
    [room.participantCount]
  )

  const recordingMessage = useMemo(
    () =>
      room.participantCount > 0 ? (
        <Grid container>
          <Grid item xs={1}>
            <FiberManualRecordIcon color="secondary" />
          </Grid>

          <Grid item xs={11}>
            <Box mt={0.35} ml={0.4}>
              <Typography
                display="inline"
                variant="body2"
                className="room-card-content-recording-message"
              >
                Session in progress. Recording may be in progress.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : null,
    [room.participantCount]
  )

  return (
    <>
      <Card
        onClick={useCallback(() => goToRoom(room), [goToRoom, room])}
        className="room-card"
        style={backgroundImage}
      >
        <CardContent className="room-card-content">
          <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid item xs={12}>
              <Grid container justify="space-between" alignItems="center">
                {room.isSuites && <Grid item xs={2}>
                  <SuiteIcon width={'48px'} height={'30px'} />
                </Grid>
                }
                <Grid item xs={room.isSuites ? 8 : 10}>
                  {recordingMessage}
                </Grid>
                <Grid container item xs={2} justify="flex-end">
                  {actionButton}
                </Grid>
              </Grid>
            </Grid>
            <Grid className="room-card-content-title without-text-overflow" item>
              {room.displayName}
            </Grid>
            <Grid container item>
              <Grid item xs={10} className="room-card-content-creator without-text-overflow">
                Room owner: {room.creator?.displayName}
              </Grid>
              <Grid item xs={2} className="participants-in-room">
                {participantIcon}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <RoomDialog
        isOpen={isRoomDialogOpened}
        room={room}
        handleClose={closeRoomDialog}
        onSuccess={onRoomDialogSuccess}
      />
      <DeleteOwnRoomDialog
        isOpen={isDeleteConfirmationOpen}
        room={room}
        handleClose={closeDeleteConfirmation}
        onConfirm={deleteLiveroom}
        isLoading={deleteLiveroomState === DeleteLiveroomState.submitting}
      />
      <RoomAccessDialog isOpen={isRoomAccessOpened} room={room} handleClose={closeRoomAccess} />
      <AppleTVLoginDialog
        isOpen={isAppleTVDialogOpened}
        room={room}
        handleClose={closeAppleTVDialog}
        onSuccess={closeAppleTVDialog}
        withCondition={!isLite}
      />
    </>
  )
}

export default RoomCard
