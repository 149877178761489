import { PermissionType } from '../Common/hooks/usePermissionsRest'
import { RoomPermissionsTypes } from '../Common/hooks/useRoomPermissionsRest'
import { PreferenceType } from '../Common/preferences/Preferences'

export interface IAlert {
  id?: number
  message: string
  class: string
  begins_at: string | number | Date
  expires_at: string | number | Date
}
export interface IClient {
  id?: number
  display_name: string
  invoiced_customer_id?: number
  company_name: string
  address1: string
  address2?: string
  province: string
  city: string
  zipcode: string
  country: string
  hubspot_deal_id?: number
  payment_method: string
  payment_term: string
  pricing_plan: number
  pricing_plan_start: string
  accounts?: Array<IAccount>
  notes?: Array<{ note: string }>
  // notes?: string
  is_active: boolean
}

export interface IPlan {
  id?: number
  plan_name: string
  plan_description: string
  base_price: string
  base_credits: number
  additional_credit_cost: string
  free_credits: number
  number_of_credits_for_bonus: number
  term: number
  is_default: boolean
  parent_plan: number | null
}

export interface RoomAPI {
  id: string
  display_name: string
  is_public: boolean
  organization_id: string
  hash: string
  creator: {
    id: string
    display_name: string
  }
  recordings: {
    id: string
    recording_id: string
    display_name: string
    started_at: string
    stopped_at: string
    created_at: string
    deleted_at: string
    is_public: boolean
    profile_id: string
  }[]
  note_count: number
  participant_count: number
  joined_at: string
  created_at: string
  deleted_at: string
  is_suites: boolean
}

export interface RoomAccess {
  room: RoomMinimalInfo | RoomFullInfo
  user?: UserAccess
}

export interface UserAccess {
  canJoinRoom: boolean
  canPublish?: boolean
  hasAccess: boolean
  hasMFASetUp: boolean
  didUserSignInWithSSO: boolean
  isMFAValid?: boolean
  permissions?: IRoomPermissions
}

export interface RoomState {
  isMFAProtected: boolean
  isDndActive: boolean
  isViewOnlyMode: boolean
}

export interface RoomRest {
  id: string
  hash: string
  createdAt: string
  deletedAt: string | null
  displayName: string
  organizationId?: string
  sessionCount: number
  noteCount: number
  participantCount: number
  isPublic: boolean
  creator: {
    id: string
    displayName: string
  }
  sessions?: RoomSession[]
  isSuites: boolean
}

export interface RoomMinimal {
  id: string
  hash: string
  display_name?: string
  room_lock: boolean
  is_public: boolean
}

export interface RoomMinimalInfo {
  id: string
  hash: string
  displayName?: string
  isPublic: boolean
  roomState: RoomState
}

export interface RoomFullInfo {
  id: string
  creatorId: string
  displayName: string
  isPublic: boolean
  hash: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  organizationId: string
  isActive: boolean
  serverAffinity?: string
  serverRegion?: string
  isServerOnPrem: boolean
  roomLock: boolean
  creator: {
    id: string
    displayName: string
  }
  participants?: {
    id: string
    profileId: string
    roomId: string
    joinedAt: string
    leftAt?: string
  }[]
  roomState: RoomState
  roomFeatures: { [key: string]: IFeatureProps }
}

export interface RoomServerUrl {
  accessToken?: string
  signalingAuthUrl?: string
  signalingUrl: string
}

export interface EBSConfig {
  signalingUrl: string
  streamKey: string | null
}

interface IFeatureProps {
  value: 'true' | 'false' | 'WILLMUTE' | 'WILLNOTMUTE' | 'PLAY' | 'CHATOFF' | 'CHATON'
  isConfigurable: boolean
}

export interface IOrganizationFeatures {
  ALLOW_PUBLIC_ROOMS: IFeatureProps
  ALLOW_RECORDING: IFeatureProps
  ALLOW_RECORDING_DL: IFeatureProps
  ALLOW_ROOM_NOTES: IFeatureProps
  ALLOW_ROOM_NOTES_DL: IFeatureProps
  ALLOW_ROOM_CHAT: IFeatureProps
  ALLOW_ROOM_CHAT_DL: IFeatureProps
  ALLOW_SCREENSHARE_2K: IFeatureProps
  ALLOW_SCREENSHARE_4K: IFeatureProps
  ALLOW_REQUIRE_ROOM_MFA: IFeatureProps
  ALLOW_ROOM_DO_NOT_DISTURB: IFeatureProps
  ALLOW_FORENSIC_WATERMARKING: IFeatureProps
}

export interface IRoomFeatures {
  ADVANCED_VIDEO_MUTE: IFeatureProps
  ALLOW_PUBLIC_ROOMS: IFeatureProps
  ALLOW_RECORDING: IFeatureProps
  ALLOW_RECORDING_DL: IFeatureProps
  ALLOW_ROOM_CHAT: IFeatureProps
  ALLOW_ROOM_CHAT_DL: IFeatureProps
  ALLOW_ROOM_NOTES: IFeatureProps
  ALLOW_ROOM_NOTES_DL: IFeatureProps
  ALLOW_SCREENSHARE_2K: IFeatureProps
  ALLOW_SCREENSHARE_4K: IFeatureProps
  ALLOW_REQUIRE_ROOM_MFA: IFeatureProps
  ALLOW_ROOM_DO_NOT_DISTURB: IFeatureProps
  CHATS: IFeatureProps
  DRAW_RECORDING: IFeatureProps
  MUTE_UPON_ENTRY: IFeatureProps
  PLAY_ENTRANCE_SOUND: IFeatureProps
  RECORDING: IFeatureProps
  ROOM_OWNER_CHAT_CONTROL: IFeatureProps
  VIDEO_WATERMARK: IFeatureProps
  ALLOW_WATERMARK: IFeatureProps
  ALLOW_FORENSIC_WATERMARKING: IFeatureProps
  ALLOW_INTEGRATION_BOX: IFeatureProps
}

export type IRoomPermissions = {
  [key in RoomPermissionsTypes]: { rooms: string[] }
}
export interface IAccount {
  id?: number
  display_name: string
  // type: string
  credentials: {
    email: string
    //   password: string
  }
}

export interface IJWtUser {
  profile_id?: string
  display_name?: string
  image?: string
  is_verified?: boolean
  profile_type?: string
  role: string
  email: string
}

export type IPermission = {
  [key in PermissionType]: boolean
}

/* Frontend-V3 */
export interface FeatureFlag<T extends string> {
  key: T
  value: string
}

export enum UserFeatureFlag {
  rooms = 'ROOMS',
  // ebsDownloadPage = 'EBS_DOWNLOAD_PAGE', // Deprecated august 2022
  browserStream = 'EXTERNAL_DEVICE_SHARE',
  createRoomByAssignment = 'CREATE_ROOM_BY_ASSIGNMENT',
}

export enum AppFeatureFlag {
  sso = 'LOGIN_SSO',
}

export enum RoomFeatureFlag {
  activeSpeakerDetection = 'ACTIVE_SPEAKER_DETECTION',
  advancedVideoMute = 'ADVANCED_VIDEO_MUTE',
  allowRecording = 'ALLOW_RECORDING',
  drawRecording = 'DRAW_RECORDING',
  chats = 'CHATS',
  allowRoomChat = 'ALLOW_ROOM_CHAT',
  allowRoomChatDl = 'ALLOW_ROOM_CHAT_DL',
  allowRoomNotes = 'ALLOW_ROOM_NOTES',
  allowRoomNotesDl = 'ALLOW_ROOM_NOTES_DL',
  videoWatermark = 'VIDEO_WATERMARK',
  customBaseBitrate = 'CUSTOM_BASE_BITRATE',
  allowWatermark = 'ALLOW_WATERMARK',
  allowRoomViewOnly = 'ALLOW_ROOM_VIEW_ONLY',
  allowPublicRooms = 'ALLOW_PUBLIC_ROOMS',
  allowRoomMFA = 'ALLOW_REQUIRED_ROOM_MFA',
  allowForensicWatermarking = 'ALLOW_FORENSIC_WATERMARKING'
}

export enum OrganizationFeatureFlag {
  allowPublicRooms = 'ALLOW_PUBLIC_ROOMS',
  allowRoomMFA = 'ALLOW_REQUIRED_ROOM_MFA',
  allowForensicWatermarking = 'ALLOW_FORENSIC_WATERMARKING'
}

export interface ProfilePreference {
  preferenceTypeName: PreferenceType
  preferenceTypeId: string
  preferenceTypeDefault: string
  preferenceValue: string | null
}

export interface RoomSession {
  id: string
  recordingId: string
  displayName: string
  startedAt: string
  stoppedAt: string
  createdAt: string
  deletedAt: string | null
}

export interface Room {
  id: string
  hash: string
  createdAt: string
  deletedAt: string | null
  displayName: string
  creatorId: string
  organizationId?: string
  sessionCount: number
  noteCount: number
  participantCount: number
  isPublic: boolean
  roomLock: boolean
  invitesByRoomId: {
    nodes: Invite[]
  }
  profileByCreatorId: {
    displayName: string
  }
  featureFlags: {
    nodes: FeatureFlag<RoomFeatureFlag>[]
  }
  featureFlagsRest?: IRoomFeatures
  sessions?: {
    nodes: RoomSession[]
  }
  messages?: {
    edges: Cursor<Message>[]
  }
}

export interface Cursor<T> {
  cursor: unknown | null
  node: T | null
}

export interface RecentRoom {
  joinedAt: string
  liveroomByRoomId: Room
}

export interface Invite {
  email: string
  id: string
  isTemporary?: boolean
}

export interface RoomInvitesAPI {
  contributors: {
    users: {
      email: string
    }[]
    accountGroups: {
      id: string
      name: string
    }[]
  }
  viewers: {
    users: {
      email: string
    }[]
    accountGroups: {
      id: string
      name: string
    }[]
  }
}

export enum RoomInviteType {
  USER = 'user',
  GROUP = 'group',
}

export interface RoomInvite {
  id: string
  name: string
  type: RoomInviteType
}

export interface Message {
  id: string
  sessionId: string
  sessionTime: any
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  profileId: string
  profileDisplayName: string
  content: string
}

export interface Note {
  id: string
  sessionId: string
  sessionTime: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  profileId: string
  profileDisplayName: string
  content: string
}

export enum UserType {
  basic = 'BASIC',
}

export interface UserProfile {
  displayName: string
  featureFlags: {
    nodes: FeatureFlag<UserFeatureFlag>[]
  }
  id: string
  nodeId: string
  organizationByOrganizationId?: any
  profilePreferences: {
    nodes: ProfilePreference[]
  }
  type: UserType
  rooms: {
    nodes: Room[]
  }
  invites: {
    nodes: any[]
  }
  recentRooms: {
    nodes: RecentRoom[]
  }
  email?: string | null
}

export interface SSODetails {
  userPoolClientId: string
  userPoolId: string
  userPoolDomain: string
  userPoolRegion: string
}

export interface StreamKey {
  uuid: string
}

export interface IPostConnectivityLog {
  createdAt: Date
  rtt: number
  uploadBitRate: number // TODO: should be bytRate
  downloadBitRate: number // TODO: should be byteRate
  id: string
  jitter: number
  streamId: string
  platform: string
  platformVersion: string
  operatingSystem: string
}

export interface IRoomAuthorize {
  isGuest: boolean
  isAccountAuthVerified: boolean
  didUserSignInWithOrgSSO: boolean
  isMFAProtected: boolean
  isUserMFAValid: boolean
  roomFeatures: IFeatureProps
  canJoinRoom: boolean
}

export interface IVerifyRoomMFA {
  isVerified: boolean
}

export interface IRoomMFA {
  isSet: boolean
}

export interface Watermark {
  id: string
  content: string
  contentType: string
  expiresAt: string
}